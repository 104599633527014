@import url('https://fonts.googleapis.com/css2?family=Outfit&family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$login-background: #f7f3E7;
$login-background-sup: #f7f3E7;
$login-btn-color: rgb(255, 80, 1);

@mixin verticalCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background: #fff;
    font-family: 'Montserrat';
    letter-spacing: 0.5px;
    display: flex;
    justify-content: center;
    color: #282A2D;
}

input[type="text"]{
    -webkit-text-fill-color: #94928B!important;
}

.login-page{
    background-color: #0a53be;
    width: 100%;
}

.login-template,
.profil-page,
.tutorial-page,
.home-page{
    margin: 0 auto;
    max-width: 450px;
}

li {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: inherit;
}

a:visited, a:focus, a:hover {
    color: inherit;
}

.unsolds-active{
    color: #F95001;
    font-variation-settings: 'FILL' 1;
}

.review-active {
    color: #F95001;
    font-variation-settings: 'FILL' 1;
}

.fa-cart-shopping{
    font-size: 22px;
}

.fa-qrcode{
    font-size: 30px;
    margin-right: 10px;
}

.unsolds-count{
    background-color: black;
    color: white;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 900;
    position: absolute;
    margin-left: 40px;
    margin-top: -2px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
}

.unsolds-recup{
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.unsolds-recup i{
    margin-right: 20px;
    font-size: 24px;
}

.unsold-unavailable{
    text-decoration: line-through;
}

.unsold-infos .fa-circle-info{
    color: rgba(1, 140, 78, 0.50);
}

.unsolds-text{
    font-size: 14px;
    font-weight: 300;
    margin: 10px 0 20px 0;
}

.unsolds-text-small{
    font-size: 12px;
    font-weight: 300;
    margin: 15px 0;
}

.unsolds-text-small a{
    color: #018C4E;
    text-decoration-line: underline;
}

.metal-detail-category-data img, .unsold-detail-category-data img{
    width: 20px;
    margin-right: 5px;
}

.info-icon{
    width: 25px;
    height: 25px;
}

.unsold-card{
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 10px;
    display: flex;
    min-height: 80px;
    margin-bottom: 20px;
    flex-direction: column;
    justify-content: center;
}

.unsold-tag{
    height: 24px;
    align-items: center;
    width: 24px;
    flex:1;
    display: flex;
}

.skip-constraint{
    border-radius: 50px;
    background: #f7f3E7;
    text-align: center;
    font-size: 16px;
    height: 40px;
    margin: 10px 10px 20px 10px;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    width: 330px;
    align-items: center;
    color: #000;
    font-weight: 600;
    text-decoration: underline;
}

.optional-meaning{
    font-style: italic;
    font-size: 10px;
}

.unsolds-card-infos{
    cursor: pointer;
}

.unsold-infos{
    color: #018C4E66;
    min-width: 75px;
}

.unsold-left{
    font-style: italic;
    font-size: 14px;
    color: #635C5C;
    margin-right: 5px;
}

.unsolds-remain{
    background-color: #018C4E;
    color: white;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
}

.unsolds-card-price{
    display: flex;
    font-weight: 700;
    color: #018C4E;
    margin-right: 45px;
    flex: 1;
}

.unsold-qty{
    border: 0;
    font-size: 16px;
    font-style: italic;
    font-weight: 700;
    margin-left: 10px;
}

.unsold-qty::-webkit-outer-spin-button,
.unsold-qty::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.unsolds-count-active{
    background-color: #018C4E;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 9999s ease-in-out 0s;
    -webkit-text-fill-color: #FFF;
}

#root{
    width: 100%;
    background-color: #f7f3E7;
}

#qrcode-scan video {
    min-width: 300px;
}

.swiper {
    height: 155px;
    width: 100%;
}

.swiper.swipper-date {
    height: 53px;

    .swiper-wrapper {
        height: 53px;

        .swiper-slide .box-calendar{
            height: 53px;
        }
    }
}

.box-calendar a{
    width: 100%;

    & div{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}

.swiper-wrapper {
    text-align: center;
}

.my-canteen{
    font-size: 16px;
    cursor: pointer;
}

.my-canteen-text{
    margin-right: 10px;
    font-weight: 900;
}

.header-avatar{
    height: 44px;
    min-width: 100px;
    max-width: 125px;
    border: 1px solid #000;
    border-radius: 30px;
    padding: 1px 2px 2px;
}

.header-avatar-name{
    height: 40px;
    width: 40px;
    border-radius: 40px;
    background-color: white;
    color: #0E2764;
    text-transform: uppercase;
}

.header-qr-code{
    //width: 85px;
}

.not-coming-label{
    text-align: center;
    margin: 0 40px 40px;
    font-size: 16px;
}

.points-gain{
    position: absolute;
    margin-top: -15px;
    background-color: rgb(255, 80, 1);
    color: white;
    border-radius: 20px;
    padding: 5px;
    margin-left: 75px;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    animation: clignotement 2s ease-in-out infinite;
    z-index: 10;
}

.header-points{
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-left: 3px;
}

//HOME
.calendar-bg{
    background-color: #EE4447;
}

.home-page .calendar_reservation .header-avatar, .unsolds .calendar_reservation .header-avatar{
    border: 1px solid white;
}

.news .calendar_reservation{
    color: black;
}

.calendar_reservation{
    flex: 1;
    justify-content: center;
}

.unsolds .calendar_reservation_new{
    background-color: #0E2764;
    font-family: Montserrat;
    padding: 10px;
}

.calendar_reservation_new #unsold-infos{
    justify-content: start;
    padding: 10px;
}

.calendar_reservation_new .my-canteen-text{
    font-family: Poppins;
    margin-left: 10px;
}

.avatar{
    width: 40px;
    height: 40px;
    border: 1px solid white;
    border-radius: 25px;
}

.home {
    min-height: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: #f7f3E7;
}

.current-day{
    background-color: #f7f3E7;

}

.dot{
    display: inline-block;
    border-radius: 50%;
    height: 10px;
    width: 10px;
    margin: 0 2px;
}

.dot-good{
    color: #018C4E;
}

.dot-neither{
    color: #FBBC05;
}

.dot-bad{
    color: #EE4447;
}

.calendar-month{
    display: flex;
    justify-content: center;
    margin-left: 12px;
    width: calc(100% - 24px);
}

.calendar-dot{
    position: absolute;
    right: 2px;
    bottom: -1px;
}

.calendar-dot i.no{
    background-color: red;
}

.calendar-dot i.yes{
    background-color: green;
}

.calendar-day{
    background-color: white;
    border-radius: 5px;
    width: 65px;
    height: 45px;
    display: flex;
    text-align: center;
    flex-direction: column;
    font-size: 14px;
}

.current-day .calendar-day {
    background-color: transparent;
}

.calenday-day-name{
    display: flex;
    font-size: 14px;
    font-weight: 900;
}

.calenday-day-number{
    display: flex;
    font-size: 16px;
    font-weight: 900;
}

.calenday-day-month{
    display: flex;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.meal-section{
    display: flex;
    margin-left: 10px;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 15px;
}

.meal-section-name{
    font-size: 16px;
    font-weight: 900;
}

.expired-meal-section-name{
    color: #635C5C;
    font-size: 22px;
    font-weight: 600;
}

.meal-section span{
    margin-right: 5px;
    margin-left: 10px;
}

.meal-section img{
    width: 24px;
}

.meal-footer{
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #f7f3E7;
    display: flex;
    width: 100% !important;
    border-top: 1px solid #C4C4C4;
    z-index: 1;
    padding: 10px 0 15px 0;
}

.day-before{
    background-color: #D9D9D9;
}

.meal-list{
    display: flex;
    flex-wrap: wrap;
    margin-left: 10px;

        input[type="checkbox"] {
            display: none;

            &:checked + span {
                border: 2px solid #FF5001;
            }
        }
}

.meal-cards-list input[type="checkbox"] {
    display: none;
}

.allergen{
    width: 12px;
    height: 12px;
}

.meal{
    background-color: white;
    border-radius: 10px;
    // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    filter: drop-shadow(rgb(204, 204, 204) 0px 2px);
    display: flex;
    flex-direction: column;
    padding: 8px;
    flex: 1;
    width: 145px;
    max-width: 145px;
    height: 140px;
    margin-right: 10px;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: -0.41px;
}

.vote-disabled{
    background-color: #DDD!important;
}

.qrcode-resa{
    width: 314px;
    text-align: center;
}

.qrcode-resa.none, .confirmation-info-borne{
    display: none;
}

.expired-date{
    font-size: 23px;
    letter-spacing: 0.5px;
    font-weight: 800;
    display: flex;
    margin-left: 10px;
    align-items: center;
}

/*.expired-confirm-resa{
    margin: 90px 15px 0;
}*/

.expired-confirm-no-resa{
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.06);
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    display: flex;
    min-height: 90px;
}

.expired-confirm-missing{
    width: 25px;
    background-color: #EE4447;
    display: flex;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.add-invit{
    background-color: rgb(255, 80, 1);
    padding: 10px;
    border-radius: 20px;
    font-weight: 500;
    font-size: 16px;
    color: white;

    &:hover, &:focus, &:active , &:visited{
        color: white;
    }
}

.expired-confirm-miss-resa{
    width: 25px;
    background-color: #F08324;
    display: flex;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.expired-info-recap{
    justify-content: center;
    height: 50px;
}

.expired-confirm-ok-resa{
    width: 25px;
    background-color: #018C4E;
    display: flex;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.expired-confirm-no-resa-text{
    padding: 15px 25px;
    justify-content: center;
    display: flex;
    align-items: center;
    width: calc(100% - 25px);
}

.expired-confirm-ok{
    margin-top: 10px;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: column;
}

.expired-confirm-ok-text{
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    color:white;
    font-size: 18px;
    font-weight: 900;
    background-color: #018C4E;
    padding: 5px;
    text-align: center;
}

.expired-confirm-ok-details{
    padding: 15px;
}

.expired-info, .last-day-info{
    margin: 30px 0 25px;
    border-radius: 50px;
    background: rgb(255, 80, 1);
    color: white;
    padding: 7px 15px;
    display: flex;
    align-items: center;
}

.expired-chosen-meal{
    color: #887E7E;
    font-size: 18px;
    font-weight: 600;
    margin-left: 15px;
}

.expired-chosen-value{
    color: #181D27;
    font-size: 16px;
    font-weight: 400;
    margin-left: 25px;
}

.expired-dish-infos{
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.avatar-name-block, .header-points{
    display: flex;
    // background-color: #F9D37A;
    width: 40px;
    border-radius: 40px;
}

.header-points{
    width: auto;
    padding: 2px 0px 2px 15px;
}

.header-points .avatar-name{
    font-weight: 300;
    justify-content: end;
    font-size: 17px;
    padding-right: 10px;
}

.avatar-name{
    font-size: 20px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
}

.expired-dish-category{
    border-radius: 20px;
    background: rgba(6, 1, 180, 0.05);
    width: 100px;
    padding: 5px 15px;
    margin: 0px 10px 0px 0px;
    font-size: 16px;
    font-weight: 600;
}

.expired-confirmation-info{
    display: flex;
    align-items: center;
}

.last-day-info{
    margin: 30px;
}

.expired-info-icon{
    font-size: 24px;
    margin-right: 10px;
    margin-left: 15px;
}

.expired-info-text{
    font-size: 15px;
    font-weight: 500;
    text-align: center;
}

.expired-info-subtext{
    font-size: 14px;
    font-weight: 400;
    text-align: center;
}

.qr-code-label{
    margin-top: 100px;
    font-size: 26px;
    font-weight: 900;
}

.meal span{
    text-align: initial;
    line-height: 15px;
}

.meal span.price{
    display: flex;
    flex: 1;
    align-items: center;
    font-size: 15px;
    color: #635C5C;
    font-weight: 400;
}

.meal_label{
    margin-right: 10px;
}

.meal.chosen{
    border: 2px solid #EE4447;
}

.no-canteen{
    margin: 30px 0;
    flex-direction: column;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    display: flex;
    padding: 20px;
}

.unsold-image{
    width: 90%;
    border-radius: 160px;
    max-height: 250px;
}

.meal-detail-category{
    list-style-type: disc;
    display: list-item;
    margin-left: 15px;
    font-size: 16px;
}

.metal-detail-category-data, .unsold-detail-category-data{
    margin-left: 15px;
}

.metal-detail-category-data i{
    color: #635C5C;
    margin-left: 4px;
}

.unsold-detail-category-data i{
    color: #635C5C;
    font-weight: 300;
}

.meal-details, .unsold-details{
    display: flex;
    margin-top: 15px;
    font-size: 14px;
    flex-direction: column;

    & div{
        margin-bottom: 10px;
    }
}

.unsold-details{
    flex-direction: row;

    & div{
        margin-bottom: 0px;
        max-width: 150px;
    }
}

.meal-back, .unsold-back{
    display: flex;
    cursor: pointer;
}

.meal-name{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    flex: 1;
}

.review-step{
    background-color: #FF500133;
    height: 6px;
    margin: 0 5px;
    border-radius: 6px;
    flex:1;
    display: flex;
}

.review-modal-title{
    color: #FF5001;
    margin: 20px 0;
}

.review-answer-chosen{
    background-color: #FF500180!important;
}

.review-question{
    font-size: 20px;
    margin: 5px 0;
}

.review-answer{
    background-color: #FF50010D;
    border: 1px solid #FF500199;
    margin: 10px 0;
    padding: 10px;
    border-radius: 10px;
}

.review-answers input{
    border: 1px solid #FF5001;
    border-radius: 10px;
    width: 100%;
    background-color: #FF500111;
    padding: 10px;
}

.review-step-active{
    background-color: #FF5001;
}

.meal-price, .unsold-price{
    display: flex;
    flex: 3;
    align-items: center;
}

.unsold-price{
    color: #018C4E;
    font-weight: 900;
    font-size: 16px;
}

.coming-btn{
    display: flex;
    flex: 1;
    max-height: 30px;
    align-items: center;
    justify-content: center;
    min-width: 150px;
}

.avatar-choice-card{
    width: 45%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.avatar-high-number{
    font-weight: bold;
    color: #FF5001;
    font-size: 36px;
    text-align: center;
}

img.avatar-selected{
    border: 3px solid black;
    border-radius: 120px;
}

.avatar-high-unity{
    color: #FF5001;
    text-align: center;
    font-size: 22px;
    margin-bottom: 30px;
}

.profile-card-name{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
}

.profile-card-cumul-points{
    font-size: 18px;
    font-family: 'Outfit';
}

.profile-card-points{
    font-size: 12px;
}

#filter-teams, #filter-individual, #filter-my-team{
    padding: 10px 20px;
    border-radius: 10px;
    margin-right: -10px;
}

.meal-submit, .join-canteen, .unsold-submit, .review-submit, .cancel-canteen{
    border-radius: 50px;
    background: rgb(255, 80, 1);
    margin: 10px 10px 20px 10px;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    font-size: 20px;
    border: 0;
    width: 330px;
    height: 40px;
    align-items: center;
    font-weight: 600;
    color: white;
}

.full-height{
    height: auto!important;
}

.challenge-plus-points{
    background: linear-gradient(45deg, #FEC258, #FEC258);
    border-radius: 30px;
    padding: 3px 5px 3px 3px;
    font-size: 7px;
    font-weight: bolder;
    letter-spacing: 0.06px;
    width: 21px;
    border: 1px solid #666;
}

.meal-submit{
    width: 150px;
}

.meal-submit:disabled{
    background-color: lightgrey;
}

.meal-filters{
    background-color: white;
    border-radius: 12px;
    padding: 5px;
    margin: 0 70px;
    filter: drop-shadow(rgb(204, 204, 204) 0px 2px);
    height: 60px;
}

#filter-all{
    padding: 10px;
    border-radius: 12px;
    width: 110px;
    margin-right: 15px;
    font-weight: lighter;
}

#filter-co2{
    padding: 5px;
    border-radius: 12px;
    // margin-right: 15px;
    font-weight: lighter;
}

.filter-active{
    // background-color: #F9D37A;
    background-color: rgb(255, 80, 1);
    color: white;
    font-weight: normal!important;
}

.cancel-canteen{
    background-color: transparent;
    font-style: italic;
    color: #635C5C;
}

.review-submit{
    background-color: rgb(255, 80, 1);
    color: white;
}

.unsold-submit{
    background-color: #018C4E;
    color: white;
}

.join-canteen{
    border: 2px solid rgb(255, 80, 1);
    background: #FFF;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
}

.join-canteen.canteen{
    border: 2px solid rgb(255, 80, 1);
    height: 50px;
}

.attendance-chart-y-legend{
    max-width: 20px;
    position: absolute;
    margin-top: 85px;
    color: rgb(99, 92, 92);
    font-style: italic;
    transform: rotate(-90deg);
}

.attendance-chart{
    display: flex;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 0.5rem !important;
    flex-direction: column;
    width: 95%;
    margin: 0 auto;
}

.attendance-chart-x-legend{
    display: flex;
}

.attendance-chart-x-legend span{
    font-size: 14px;
    font-weight: 300;
    letter-spacing: -0.32px;
}

input[name="attendance"]{
    transform: scale(1.7);
    margin: 5px 0;
}

.meal-detail-submit{
    font-size: 15px;
    padding: 5px 50px;
    font-weight: bold;
}

.unsold-tag img{
    width: 24px;
}

.review-meal{
    display: flex;
    font-size: 16px;
    min-width: 130px;
    margin: 10px;
    justify-content: center;
}

.review-star{
    margin-right: 3px;
    padding: 5px;
    width: 35px;
    height: 35px;
}

.menu-icon{
    width: 24px;
    height: 24px;
}

.menus-label, .menu-active, .home-label, .home-active{
    color: #F95001;
    font-variation-settings: 'FILL' 1;
    font-weight: 500;
}

.jabu-label{
    color: #F95001;
    font-weight: 500;
}

.review-label {
    color: #F95001;
    font-weight: 500;
}

.unsold-label{
    color: #F95001;
    font-weight: 500;
}

.news-label{
    color: #F95001;
}

.menu-label{
    font-size: 12px;
}

.meal-accompaniements{
    font-size: 14px;
}

.meal-accompaniements input{
    margin-left: 5px;
}

.meal-accompaniements-choices{
    font-size: 14px;
    margin: 10px auto 0;
}

.meal-info{
    position: relative;
    margin-right: -15px;
}

.meal-info-f{
    font-size: 13px;
    letter-spacing: -0.08px;
    color: #FF5001;
    border-radius: 25px;
    border: 1px solid #FF5001;
    padding: 5px 20px;
    text-align: center !important;
}

.fa-circle-info{
    color: #BBB;
}

.expired-info-icon .fa-circle-info{
    color: black;
}

.fa-circle-info::before{
    background-color: white;
    border-radius: 50px;
}

.news-active{
    color: #F95001;
}

#not-coming, #coming, #not-coming-selected{
    width: 227px;
    height: 53px;
    border-radius: 5px;
    border: 1px solid #C4C4C4;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #635C5C;
    font-size: 20px;
    display: flex;
    align-items: center;
}

#not-coming-selected{
    border: 2px solid #EE4447;
    color: #EE4447;
}

#coming-ok{
    color: #018C4E;
    font-weight: 700;
    border: 0;
    background: inherit;
    border-bottom: 2px solid rgba(1, 140, 78, 0.2);
    display: flex;
    align-items: center;
    margin-right: 90px;
}

#not-coming-ok{
    color: #635C5C;
    font-weight: 400;
    border: 0;
    background: inherit;
    display: flex;
    align-items: center;
}

#not-coming-ok span{
    width: 62px;
    display: flex;
    font-size: 20px;
    justify-content: center;
    margin-right: 15px;
}

#coming-ok span{
    width: 94px;
    display: flex;
    font-size: 20px;
    justify-content: center;
}

#coming-ok i, #not-coming-ok i{
    font-size: 24px;
}

#coming{
    margin-bottom: 20px;
}

#not-coming span, #coming span, #not-coming-selected span{
    margin-left: 10px;
    display: flex;
    flex: 1;
}

#not-coming i, #coming i, #not-coming-selected i{
    margin-right: 10px;
    font-size: 24px;
}

.meal-complete-day{
    margin-top: 28px;
    font-size: 28px;
    font-weight: 800;
}

.presence-complete-day{
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    flex: 1;
}

.presence-message{
    margin:0 40px 40px;
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    text-align: center;
    font-size: 20px;
    min-height: 265px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.presence-message-ok{
    border: 2px solid #018C4E;
    font-weight: 700;
}

.presence-message-ko{
    border: 2px solid #EE4447;
    font-weight: 500;
}

.review-headtitle{
    margin: 28px 0;
    font-size: 24px;
}

#not-coming i{
    color: #EE4447;
}

#coming i{
    color: #018C4E;
}

.meal-info img{
    position: absolute;
    bottom: -9px;
    right: 18px;
    width: 24px;
}

a.link{
    color: #0B50A4;
    text-decoration: underline;
}

.save-title{
    display: flex;
    justify-content: center;
    font-weight: bold;
    margin-bottom: 10px;
}

.save-content{
    text-align: center;
}

.save-logo{
    margin-right: 10px;
}

.d-flex p.question{
    // text-decoration: underline;
    width: 100%;
    font-weight: 700;
}

//Confirmation
.confirmation-info{
    font-weight: bold;
    font-size: 20px;
    font-style: italic;
    display: flex;
    flex: 5;
    justify-content: center;
}

.close-confirmation{
    display: flex;
    margin-right: 5px;
}

.close-confirmation img{
    width: 30px;
    height: 30px;
}

.gain-points{
    display: flex;
    background-color: rgb(255, 80, 1);
    color: white;
    width: 300px;
    height: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 300px;
}

.points-info{
    display: flex;
    text-align: center;
    max-width: 320px;
    font-size: 20px;
    color: #635C5C;
    margin: 50px 0;
}

.points-claimed{
    font-size: 80px;
    font-weight: bold;
}

.points-claimed-info{
    max-width: 200px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.next-day, .see-points{
    height: 40px;
    width: 330px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    border-radius: 50px;
}

.next-day{
    background-color: rgb(255, 80, 1);
    border: 0;
    color: white;
}

.presence-continue{
    border-radius: 50px;
    border: 0;
    color: white;
    width: 100%;
    color: white;
    padding: 7px;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
}

.presence-ok-continue{
    background-color: #018C4E;
}

.presence-ko-continue{
    background-color: #EE4447;
}

.see-points{
    background-color: white;
    border: 2px solid rgb(255, 80, 1);
    color: black;
}

.presence-ok-see-points{
    border: 1px solid #018C4E;
    width: 100%;
}

.presence-ko-see-points{
    border: 1px solid #EE4447;
    width: 100%;
}

//User points
.points-wc{
    width: 135px;
    max-height: 48px;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 65px;
}

.home-header{
    min-height: 50px;
    justify-content: center;
}

.points-header{
    min-height: 50px;
    justify-content: center;
}

.points-icon{
    width: 48px;
    height: 48px;
}

.points-balance{
    font-weight: bold;
    font-size: 16px;
    margin-left: 10px;
}

.meal-saves{
    width: 115px;
    height: 65px;
    background-color: rgb(255, 80, 1);
    color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    margin-left: 5px;
    cursor: pointer;
}

.meal-save-value{
    font-size: 20px;
    font-weight: bold;
}

.meal-save-currency{
    font-size: 16px;
}

//user profile
.profile-header{
    background-color: #f7f3E7;
    max-height: 50px;
    min-height: 50px;
    height: 50px;
    position: fixed;
    z-index: 10;
}

.profile-header-back{
    margin-left: 15px;
}

.logo-tiny{
    width: 40px;
}

.profile-header-text{
    font-size: 18px;
    font-weight: 500;
    margin-right: 25px;
}

.profile-block{
    width: calc(100% - 20px);
    margin: 10px;
    margin-top: 60px;
}

.profile-info{
    background-color: rgb(255, 80, 1);
    color: white;
    width: 100%;
    border-radius: 5px;
    height: 60px;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.profile-section-title{
    font-size: 20px;
    color: #181D27;
    opacity: 0.8;
    margin-top: 10px;
    margin-left: 10px;
}

.profile-section-content{
    background-color: white;
    padding: 5px;
    border-radius: 20px;
}

.profile-username{
    font-weight: bold;
    font-size: 18px;
}

.profile-email{
    font-size: 12px;
}

.points-img{
    border-radius: 48px;
    border: 1px solid black;
}

.profile-points{
    width: 140px;
    background-color: white;
    height: 60px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 10px 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.profile-points-amount{
    margin-left: 5px;
    font-size: 14px;
    font-weight: bold;
}

.profile-section{
    height: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.profile-section-link{
    cursor: pointer;
}

.logout-confirm{
    border-radius: 10px;
    background:rgb(255, 80, 1);
    color: white;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height: 50px;
    font-size: 20px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    margin-bottom: 25px;
    cursor: pointer;
}

.logout-confirm-title{
    font-weight: 500;
    color: #181D27;
    font-size: 20px;
    margin-bottom: 30px;
}

.logout-cancel  {
    border-radius: 10px;
    border: 1px solid #ECE9EC;
    background: rgba(217, 217, 217, 0.20);    
    height: 50px;
    font-size: 20px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    cursor: pointer;
}

.profile-info div img{
    width: 48px;
    height: 48px;
    margin: 0 5px;
}

.profile-section-icon{
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    margin-right: 20px;
    background-color: rgb(6, 1, 180, 0.05);
}

.profile-section-icon img{
    width: 24px;
    height: 24px;
}

.profile-section-name{
    font-size: 17px;
    color: #181D27;
}

.profile-section-info{
    font-size: 12px;
    color: #ABABAB;
}

.profile-section-back{
    margin-right: 10px;
}

//User infos
.user-title{
    line-height: normal;
    color: black;
    margin: 15px 0;
    text-align: center;
    font-size: 26px;
    font-weight: 800;
    letter-spacing: 0.5px
}

.user-subtitle{
    text-align: center;
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    margin: 0 15px;
}

.user-constraints{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    flex-wrap: wrap;

    label {
        margin: 20px 0;

        input[type="checkbox"] {
            display: none;

            &:checked + span img {
                filter: grayscale(0) opacity(1)
            }
        }
    }
}

span i.fa-solid, span i.fa-regular{
    font-size: 24px;
}

#meal-infos, #unsold-infos, #news-infos, #profile-infos, #review-infos, #unsold-cart-infos{
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 51px;
    margin-top: 50px;
    align-items: center;
}

#unsold-cart-infos{
    justify-content: start;
    margin-top: 70px;
}

#meal-infos{
    margin-bottom: 115px;
    width: 100%;
}

#review-infos{
    justify-content: start;
    margin-top: 105px;
    margin-bottom: 130px;
}

.review-votes{
    margin-bottom: 35px;
}

.review-title{
    margin: 0 55px 80px;
    font-size: 18px;
    text-align: center;
}

.review-comments{
    width: 330px;
    height: 85px;
    resize: none;
    border-radius: 10px;
    background: #FFF;
    padding: 15px 10px;
    border: 0;

    &:focus {
        outline: none !important;
        border:1px solid rgb(255, 80, 1);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
}

.review-vote-not-chosen{
    filter: grayscale(0.8);
    cursor: pointer;
}

.review-vote-chosen{
    filter: grayscale(0);
    cursor: pointer;
    transform: scale(1.3);
}

#chosen-coming-ok, #chosen-not-coming-ok{
    font-weight: 200;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 190px;
    height: 40px;
    border-radius: 20px;
    margin-right: 5px;
    filter: drop-shadow(rgb(204, 204, 204) 0px 2px);

    & span{
        margin-left: 15px;
    }
}

.co2{
    font-size: 10px;
    border-radius: 25px;
    font-weight: bold;
    letter-spacing: 0.06px;
    max-width: 80px;
    padding: 6px;
    margin-bottom: 5px;
}

.co2-good{
    background-color: #00805924;
    color: #008059;
}

.co2-neither{
    background-color: #FFAB0224;
    color: #FFAB02;
}

.co2-bad{
    background-color: #FF500124;
    color: #FF5001;
}

.coming-choice{
    width: 100%;
    background-color: #f7f3E7;
    padding-bottom: 20px;
}

#chosen-not-coming-ok, .chosen-evening-ok{
    background-color: white;
    border: 0;
}

.chosen-noon-ok, .chosen-evening-ok{
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 40px;
    border-radius: 20px;
    margin-right: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: 0;
}

#chosen-coming-ok, .slot-type-active{
    background-color: rgb(255, 80, 1);
    border: 0;
    color: white;
}

#unsold-infos, #news-infos{
    padding: 20px;
}

#unsold-infos{
    margin-top: 120px;
    margin-bottom: 0;
}

.meal-header{
    position: fixed;
    width: 100%;
    max-width: 450px;
    top: 0;
    display: flex;
    min-height: 50px;
    z-index: 2;
    padding: 0 15px;
    background-color: #f7f3E7;
    align-items: center;
}

.home-head{
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: bolder;
    display: flex;
}

.home-menu-link{
    font-size: 14px;
    // text-decoration: underline;
    display:flex;
    flex: 1;
    justify-content: end;
}

.home-vote-closed{
    padding: 3px 10px;
    background-color: rgb(255, 80, 1);
    font-size: 12px;
    font-weight: 500;
    border-radius: 15px;
    width: 88px;
    color: white;
}

.home-vote-confirmed{
    padding: 3px 10px;
    background-color: #018C4E;
    color: white;
    font-size: 14px;
    font-weight: bold;
    border-radius: 15px;
    width: 100px;
}

.home-voting{
    display: flex;
    background-color: white;
    border-radius: 15px;
    // align-items: center;
}

.home-info-closing{
    border-radius: 20px;
    // background-color: #F9D37A40;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-page .meal-cards-list .swiper-slide{
    margin-right: 10px;
}

.home-vote-info{
    background: rgb(255, 80, 1);
    color: white;
    border-radius: 12px;
    padding: 10px;
    margin: 3px;
    display: flex;
    flex-direction: column;
    filter: drop-shadow(rgb(204, 204, 204) 0px 2px);
}

.meal-header input, .meal-header fieldset{
    display: none;
}

.challenge-add-teamates{
    font-size: 16px;
    background-color: white;
    border-radius: 20px;
    padding: 8px;
    // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    filter: drop-shadow(rgb(204, 204, 204) 0px 2px);
}

.team-avatar{
    border: 1px solid black;
    border-radius: 20px;
    width: 40px;
    height: 40px;
}

.my-team{
    background-color: #F9D37A!important;
}

.challenge-week{
    background-color: white;
    padding: 10px 5px;
    display: flex;
    border-radius: 12px;
    color: black;
    justify-content: center;
    align-items: center;
}

.home-team-action{
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 20px;
    padding: 10px;
    flex-direction: column;
    flex: 1;
    margin: 0 10px;

    & div.action-text{
        margin-top: 10px;
        text-align: center;
    }
}

.challenge-week-day{
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;

    &.current-day{
        background-color: #F9D37A;
        border-radius: 10px;
        padding: 5px 0;

        & .no-voting{
            color: transparent;
        }
    }

    &.future-day{
        color: #838383;
    }

    & .not-voting{
        color: red;
        font-size: 9px;
    }

    & .voting{
        color: green;
        font-size: 9px;
    }

    & .no-voting{
        color: white;
        font-size: 9px;
    }

    & .dayNumber{
        font-size: 20px;
        font-weight: 600;
    }

    & .dayWeek{
        font-size: 12px;
    }
}

.challenge-ranking{
    display: flex;
    background-color: white;
    margin: 5px 0px;
    border-radius: 15px;
    align-items: center;
    padding: 5px;
    filter: drop-shadow(rgb(204, 204, 204) 0px 2px);
}

.home-vote-submit{
    background-color: white;
    color:black;
    padding: 10px 30px;
    width: 170px;
    text-align: center;
    border-radius: 25px;
    font-weight: 500;
    font-family: Poppins;
    letter-spacing: -0.08px;
}

.not-coming-active{
    border: 2px solid #FF5001 !important;
}

.user-constraint{
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 155px;
    margin: 0 10px;
    padding: 5px 10px;
    font-size: 14px;
    text-align: center;
}

.user-constraint span{
    text-align: center;
    font-size: 20px;
}

.user-input{
    width: 330px;
    margin: 10px 0 !important;
    padding: 0 15px;
}

.user-canteen {
    width: 330px;
    height: 50px;
    margin: 5px 25px;
    padding: 0 15px;
    border: 0;
    border-radius: 5px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    background-color: white;
    cursor: pointer;

    p {
        line-height: 50px;
        margin: 0;

        img {
            text-align: right;
            max-width: 20px;
        }
    }

    &.selected {
        border: 2px solid rgb(255, 80, 1);
        cursor: default;
    }
}

.default-canteen{
    color: rgb(255, 80, 1);
    font-size: 20px;
}

.canteen-block {
    margin: 10px 5px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #CDCDCD;
}

.user-input-optional, .user-input-mandatory-catchphrase, .forgot-password-link{
    color: #635C5C;
    font-size: 12px;
    margin-top: 5px;
    font-family: 'Outfit', sans-serif;
}

.user-input-mandatory-catchphrase{
    display: flex;
    width: calc(100% - 50px);
    justify-content: center;
}

.forgot-password-link{
    text-decoration-line: underline;
}

.user-input-optional + input {
    margin-top: 0;
}

.user-submit{
    width: 330px;
    background-color: rgb(255, 80, 1);
    border: 0;
    height: 40px;
    margin-top: 30px;
    border-radius: 50px;
    font-size: 20px;
    font-weight: 600;
    color: white;
}

.user-submit-satisfaction {
    width: 330px;
    background-color: rgb(255, 80, 1);
    border: 0;
    height: 40px;
    margin-top: 30px;
    border-radius: 50px;
    font-size: 20px;
    font-weight: 600;
    color: white;
}

.user-child-add {
    width: 50px;
    border: 1px solid rgb(255, 80, 1);
    background-color: #fff;
    height: 40px;
    border-radius: 50px;
    font-size: 20px;
    font-weight: 600;
    color: #000;
}

.user-child-delete {
    width: 330px;
    border: 1px solid rgb(255, 80, 1);
    background-color: #fff;
    height: 40px;
    border-radius: 50px;
    font-size: 20px;
    font-weight: 600;
    color: #000;
}

.user-child-button {
    width: 330px;
    background-color: #FF50010D;
    height: 50px;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 600;
    border: 1px #FF500199 solid;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.tutorial-tips{
    display: flex;
    font-size: 16px;
    margin: 0 25px;
}


.confirm-presence{
    background: #f7f3E7;
    padding: 15px;
}

.comfirm-presence-title{
    font-size: 20px;
    font-style: italic;
    font-weight: 900;
    padding: 25px;
    text-align: center;
    color: #FFF;
}

.comfirm-presence-ok-title{
    background-color: #018C4E;
}

.comfirm-presence-ko-title{
    background-color: #EE4447;
}

.unsolds-submit{
    color: white;
    background-color: #018C4E;
}

.tutorial-page{
    background: #f7f3E7;
    padding-top: 55px;
    min-height: 700px;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.back-meal-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.tutorial-skip{
    color: #887E7E;
    font-weight: 500;
    display: flex;
    justify-content: end;
    margin-right: 20px;
    height: 24px;
}

.tutorial-title{
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    height: 40px;
    letter-spacing: 0.5px;
    margin-top: 30px;
    margin-bottom: 20px;
    font-family: 'Poppins';
    display: flex;
    justify-content: center;
}

.tutorial-content{
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.5px;
    text-align: center;
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin: 0 25px;
    text-align: center;
}

.tutorial-subtitle{
    display: flex;
    letter-spacing: 0.5px;
    flex: 1;
    margin: 0 25px;
    justify-content: center;
    font-size: 24px;
    align-items: center;
    text-align: center;
}

.tutorial-nav-button{
    font-size: 40px;
    color: #FF5001;
}

.unsolds-cart-button{
    color: #0E2764;
    font-size: 40px;
}

i.unsolds-cart-button.fa-circle-chevron-left{
    margin: 0;
}

.cart-return{
    padding-top: 10px;
    padding-left: 14px;
    display: flex;
}

.cart-return .day{
    display: flex;
    justify-content: center;
    flex: 1;
    margin-left: -40px;
    font-family: Poppins;
}

.cart-summary{
    background-color: white;
    min-height: 300px;
    padding-bottom: 20px;
    border-bottom: 1px solid #887E7E;
}

.cart-content{
    font-family: Montserrat;
    font-size: 16px;
    margin-top: 20px;
}

.cart-pay-infos{
    font-family: Montserrat;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 30px 0;
}

.unsolds-new-text{
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 30px;
}

.unsolds-new-text-small{
    margin-top: 30px;
    font-size: 12px;
    font-weight: 200;
}

.apple-pay{
    background-color: black;
    border-radius: 20px;
    margin: 0 30px;
    width: calc(100% - 60px);
    height: 40px;
    font-family: Montserrat;
    color: white;
    font-size: 16px;
    font-weight: bold;
}

.credit-card{
    background-color: #0E2764;
    border-radius: 20px;
    margin: 10px 30px;
    width: calc(100% - 60px);
    height: 40px;
    font-family: Montserrat;
    color: white;
    font-size: 16px;
    font-weight: bold;
}

.apple-pay-text, .credit-card-text{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
}

.fa-apple{
    font-size: 30px;
    margin-right: 15px;
}

.unsolds-new-text-small a{
    color: #0E2764;
    text-decoration: underline;
}

.cart-line{
    display: flex;
    padding-left: 20px;
    padding-right: 10px; 
    margin: 15px 0;
}

.cart-line-text{
    display: flex;
    flex: 1;
}

.cart-line-amount{
    font-weight: bold;
    color: #F36E40;
}

.cart-retrieve{
    font-family: Montserrat;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.cart-retrieve div{
    margin-left: 20px;
}

div i.fa-solid.step-inactive{
    font-size: 50px;
    color: #FF500133;
}

div i.fa-solid.step-active{
    font-size: 50px;
    color: #FF5001;
}

.tutorial-go{
    color: white;
    background-color: #FF5001;
    font-size: 20px;
    font-weight: 700;
    padding: 9px 14px;
    border-radius: 30px;
    margin-right: 20px;
}

.tutorial-image{
    min-height: 270px;
    display: flex;
    align-items: center;
}

.tutorial-navigation, .tutorial-navigation-ready{
    display: flex;
    justify-content: center;
    background-color: #f7f3E7;
    min-width: 100%;
    padding-bottom: 45px;
    bottom: 0;
}

.tutorial-navigation-ready{
    flex-direction: column;
}

.tutorial-navigation-button{
    min-width: 40px;
    display: flex;
    align-items: center;
}

i.fa-circle-chevron-left{
    margin-left: 25px;
}

i.fa-circle-chevron-right{
    margin-right: 25px;
}

.unsold-back i{
    font-size: 40px;
    color: #018C4E;
    margin-left: 0;
    margin-left: 10px;
}

.cart-total{
    display: flex;
    justify-content: center;
    width: 200px;
    height: 40px;
    background-color: #88E52433;
    border: 1px solid #88E524;
    color: #018C4E;
    font-family: Montserrat;
    border-radius: 20px;
    font-weight: bold;
    align-items: center;
}

.tutorial-ready{
    border-radius: 50px;
    background: #0B50A4;
    color: white;
    padding: 15px 65px;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.5px;
    width: 320px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 20px;
}

.tutorial-maybe-later{
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.confirm-meal{
    margin-bottom: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.user-constraint img{
    width: 100px;
    height: 100px;
    filter: grayscale(1) opacity(0.5);
}

.user-constraint .user-constraint-name{
    margin-top: 2px;
    word-break: break-word;
    max-width: 155px;
    text-align: center;
    font-size: 16px;
}

.user-settings-actions{
    position: fixed;
    bottom: 0;
    //background-color: #f7f3E7;
}

.review-vote{
    width: 70px;
}

.delete-canteen{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    outline: 1px solid rgb(255, 80, 1);
    border: 0;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
    background-color: white;
}

.delete-canteen:disabled{
    display: none;
}

.delete-canteen:checked{
    background-color: rgb(255, 80, 1);
    color: white;
    outline: 0px;
}

.delete-canteen:checked label:after{
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
}

@keyframes clignotement {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.news img.avatar, .my-points img.avatar{
    border: 1px solid black;
}

.alert-allergen{
    color: #EE4447;
    font-size: 20px;
    font-weight: 700;
    text-align: right!important;
    margin-right: 15px;
}

.cgu a{
    text-decoration: underline;
}

.welcome-points-container{
    position: fixed;
    bottom: 100px;
}

.welcome-points{
    border-radius: 50px;
    background: rgb(255, 80, 1);
    color: white;
    display: flex;
    padding: 10px 30px;
    align-items: center;
    margin: 0 15px;
}

.welcome-points-text{
    font-weight: 600;
    margin-left: 10px;
    text-align: center;
    font-size: 19px;
}

.full-logo{
    width: 345px;
    height: 90px;
    object-fit: cover;
}

.calendar-datepicker {
    border-radius: 4px;
    border: none;

    .MuiOutlinedInput-root {
        background-color: #fff;
        border-radius: 20px;

        input {
            text-align: center;
        }
    }
}

.user-point-classement {
    background-color: #fff;
    border-radius: 20px;
    padding: 10px 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.canteen-point-classement {
    background-color: #fff;
    border-radius: 20px;
    padding: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.podium {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 80px;
}

.podium div {
    display: flex;
    width: 80px;
    text-align: center;
    color: white;
    background-color: rgb(255, 80, 1);
    justify-content: center;
    align-items: center;
    margin: 2px;
    border-radius: 5px;
}

.first-place {
    height: 80px;
}

.second-place {
    height: 40px;
}

.third-place {
    height: 30px;
}

.profile-game{
    width: 140px;
    background-color: white;
    height: 60px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 10px 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

input[type="radio"].review-down,
input[type="radio"].review-up{
    display: none;

    & + label i {
        cursor: pointer;
    }
}

input[type="radio"].review-down:checked + label i {
    color: red;
}

input[type="radio"].review-up:checked + label i {
    color: green;
}

.user-review {
    .MuiFormControl-root,
    .MuiFormGroup-root {
        width: 100%;
    }
    .MuiFormControlLabel-label {
        font-size: 12px;
    }
    .MuiFormControlLabel-root {
        width: 25%;
        margin: 0;
        padding: 5px;
    }
}

@media (min-width: 440px) and (max-width: 445px){
    .confirmation-info-borne{
        display: flex;
    }
}
