.login-page {
    background-color: $login-background;
    display: flex;
    min-height: 100vh;

    ::-webkit-calendar-picker-indicator {
        filter: invert(1);
    }

    input::placeholder
    {
        font-family: 'Montserrat';
        font-size: 14px;
    }

    .login-template {
        padding: 30px 10px;
        display: flex;
        flex: 1;

        .login-subtext {
            font-size: 24px;
            font-weight: 900;
            margin: 15px 0;
            text-align: center;
            font-family: 'Poppins';
        }

        hr {
            border: 0;
            font-size: 14px;
            height: 1.5em;
            line-height: 1em;
            position: relative;
            text-align: center;
            opacity: inherit;
            width: 100%;
            color: black
        }

        hr::before {
            content: "";
            background: #000;
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            height: 1px;
        }

        hr::after {
            color: black;
            content: attr(data-content);
            line-height: 1.5em;
            padding: 0 7px;
            position: relative;
            background: $login-background-sup;
        }

        .login-form {
            width: 90%;
            background: $login-background-sup;
            border-radius: 10px;
            margin: 10px 0;
            padding: 20px;
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            form {
                max-width: 400px;
                min-width: 300px;
                width: 90%;
            }

            .login-field {
                margin: 10px 0;
            }

            .login-submit {
                color: white;
                max-width: 400px;
                min-width: 300px;
                padding: 10px;
                background-color: $login-btn-color;
                font-weight: 500;
                margin: 10px 0;
                border: 0;
                border-radius: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                letter-spacing: 0.5px;

                & img{
                    margin-right: 10px;
                    display: flex;
                }

                & span{
                    display: flex;
                    justify-content: center;
                    font-weight: 500;
                    letter-spacing: 0.5px;
                }

                &.google {
                    background-color: #ECE9EC;
                    color: black;
                }

                &.apple {
                    background-color: #000;
                    color: white;
                }

                &.facebook {
                    background-color: #1977F3;
                    color: #FFF;
                }

                &.qr-code-button {
                    background-color: #FFF;
                }
            }

            .login-forgot-pass {
                max-width: 400px;
                min-width: 300px;
                width: 90%;
                padding-bottom: 15px;
                text-align: center;

                a {
                    text-decoration: none;
                    color: #FA4A0C;
                    font-size: 12px;
                }
            }

            .login-new {
                padding-top: 20px;
                max-width: 400px;
                min-width: 300px;
                width: 90%;
                display: flex;
                justify-content: center;
                font-size: 12px;

                & a {
                    color: #FA4A0C;
                }
            }

            .login-back {
                width: 90%;
                padding-top: 20px;

                a:hover {
                    text-decoration: underline;
                    color: #FFF;
                }
            }

            .qr-code {
                text-align: center;

                img {
                    padding-top: 10px;
                    cursor: pointer;
                }

                input {
                    display: none;
                }
            }
        }
    }
}

.login-subtitle{
    font-size: 12px;

    & a{
        color: #FA4A0C;
    }
}

.login-active{
    text-decoration: underline;
    text-decoration-color: #FA4A0C;
    text-underline-offset: 8px;
    text-decoration-thickness: 3px;
}

.social-icon{
    display: flex;
    flex: 1;
    justify-content: center;
}

.social-text{
    display: flex;
    flex: 3;
    margin-left: 15px;
}

#error-accept-cgvu{
    display: none;
    font-size: 13px;
    color: red;
    text-align: center;
    margin-top: 10px;
}

.desktop-login-template{
    display: flex;
    flex: 1;

    & img{
        width: 100%;
    }
}

@media (min-width: 320px) and (max-width: 500px){
    .login-page{
        height: auto;
    }

    .login-page .login-template{
        width: 100%;
    }

    .desktop-login-template{
        display: none;
    }
}
